.textCenter {
  text-align: center;
}

.preWrap {
  white-space: pre-wrap;
}

.pointer {
  cursor: pointer;
}

.fullWidth {
  width: 100%;
}
