.video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100000;
}

.rectangle {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 80px;
  width: 240px;
  margin-top: -40px;
  margin-left: -120px;
  z-index: 200000;
  border: 5px solid red;
}

.close {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  margin-bottom: 10vh !important;
  margin-left: -5rem !important;
  width: 10rem;
  z-index: 250000;
}
