.close {
  position: absolute !important;
  right: 8px !important;
  top: 8px !important;
  color: gray !important;
}

.textarea {
  resize: vertical;
}
