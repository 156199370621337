.logoArea {
  display: block;
  .logo {
    display: block;
    margin: 0 auto;
    max-width: 300px;
    cursor: pointer;
  }
}

.helpGuide {
  margin: 3vh 0 0;
  text-align: right;
}

.userArea {
  margin: 5vw;
  text-align: center;
}
